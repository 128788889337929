// import React, { useState, useEffect, useRef } from 'react';

// const SpeechToText = () => {
//   const [transcript, setTranscript] = useState(''); // Holds the transcribed text
//   const [isListening, setIsListening] = useState(false); // To toggle the microphone
//   const websocketRef = useRef(null); // Reference to WebSocket connection
//   const mediaRecorderRef = useRef(null); // Reference to MediaRecorder
//   const streamRef = useRef(null); // Reference to the audio stream

//   useEffect(() => {
//     // Cleanup function for WebSocket when component unmounts
//     return () => {
//       if (websocketRef.current) {
//         websocketRef.current.close();
//       }
//       if (mediaRecorderRef.current) {
//         mediaRecorderRef.current.stop();
//       }
//       if (streamRef.current) {
//         streamRef.current.getTracks().forEach(track => track.stop());
//       }
//     };
//   }, []);

//   const startListening = async () => {
//     try {
//       setIsListening(true);

//       // Access the microphone using MediaDevices API
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       streamRef.current = stream; // Save the stream reference

//       // Create a WebSocket connection to Deepgram's real-time API
//       websocketRef.current = new WebSocket(
//         'wss://api.deepgram.com/v1/listen',
//         ['token', '8672cfa8205eef82cb5654a7b64b7320d6ee38a5'] // Replace with your Deepgram API key
//       );

//       websocketRef.current.onopen = () => {
//         console.log('WebSocket connection established with Deepgram.');

//         // Start capturing the audio stream and sending it to Deepgram
//         mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm; codecs=opus' });
        
//         mediaRecorderRef.current.onstart = () => {
//           console.log('Recording started.');
//         };

//         mediaRecorderRef.current.ondataavailable = (event) => {
//           if (event.data.size > 0 && websocketRef.current.readyState === WebSocket.OPEN) {
//             websocketRef.current.send(event.data); // Send audio to Deepgram WebSocket
//           }
//         };

//         mediaRecorderRef.current.start(250); // Send audio in small chunks every 250ms
//       };

//       websocketRef.current.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         if (data.channel && data.channel.alternatives && data.channel.alternatives[0]) {
//           // Append the transcript
//           const transcriptPart = data.channel.alternatives[0].transcript;
//           console.log('Received transcription:', transcriptPart);
//           setTranscript((prev) => prev + ' ' + transcriptPart);
//         }
//       };

//       websocketRef.current.onerror = (error) => {
//         console.error('WebSocket error:', error);
//         stopListening(); // Stop listening on error
//       };

//       websocketRef.current.onclose = () => {
//         console.log('WebSocket connection closed.');
//         stopListening(); // Ensure clean stop
//       };

//     } catch (error) {
//       console.error('Error accessing the microphone:', error);
//       setIsListening(false);
//     }
//   };

//   const stopListening = () => {
//     setIsListening(false);
//     if (websocketRef.current) {
//       websocketRef.current.close(); // Close the WebSocket connection
//     }
//     if (mediaRecorderRef.current) {
//       mediaRecorderRef.current.stop(); // Stop recording
//     }
//     if (streamRef.current) {
//       streamRef.current.getTracks().forEach(track => track.stop()); // Stop all audio tracks
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', padding: '20px' }}>
//       <h1>Deepgram Real-Time Speech to Text</h1>
//       <div>
//         <button onClick={startListening} disabled={isListening}>
//           Start Listening
//         </button>
//         <button onClick={stopListening} disabled={!isListening}>
//           Stop Listening
//         </button>
//       </div>
//       <div style={{ marginTop: '20px', padding: '20px', border: '1px solid #ccc', minHeight: '150px' }}>
//         <h3>Transcript:</h3>
//         <p>{transcript}</p>
//       </div>
//     </div>
//   );
// };

// export default SpeechToText;

import "./App.css";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import useClipboard from "react-use-clipboard";
import { useState, useEffect } from "react";

const App = () => {
  const [textToCopy, setTextToCopy] = useState("");
  const [isCopied, setCopied] = useClipboard(textToCopy, {
    successDuration: 1000,
  });
  const [isListening, setIsListening] = useState(false); // For UI indication
  const [isPaused, setIsPaused] = useState(false);

  const startListening = () => {
    setIsListening(true);
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const stopListening = () => {
    setIsListening(false);
    SpeechRecognition.stopListening();
  };

  const pauseListening = () => {
    setIsPaused(true);
    SpeechRecognition.stopListening();
  };

  const resumeListening = () => {
    setIsPaused(false);
    startListening();
  };

  const saveTranscript = () => {
    const blob = new Blob([textToCopy], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "transcript.txt";
    link.click();
  };

  const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useEffect(() => {
    let formattedTranscript = transcript
      .replace(/comma/gi, ",")
      .replace(/full stop/gi, ".")
      .replace(/question mark/gi, "?")
      .replace(/exclamation mark/gi, "!")
      .replace(/colon/gi, ":")
      .replace(/semicolon/gi, ";")
      .replace(/dash/gi, "-")
      .replace(/open quote/gi, "“")
      .replace(/close quote/gi, "”")
      .replace(/open parenthesis/gi, "(")
      .replace(/close parenthesis/gi, ")")
      .replace(/ellipsis/gi, "…")
      .replace(/apostrophe/gi, "'")
      .replace(/at symbol/gi, "@")
      .replace(/hashtag/gi, "#")
      .replace(/dollar sign/gi, "$")
      .replace(/percent sign/gi, "%")
      .replace(/ampersand/gi, "&")
      .replace(/asterisk/gi, "*")
      .replace(/forward slash/gi, "/")
      .replace(/backslash/gi, "\\")
      .replace(/greater than/gi, ">")
      .replace(/less than/gi, "<")
      .replace(/equals sign/gi, "=")
      .replace(/plus sign/gi, "+")
      .replace(/minus sign/gi, "-")
      .replace(/underscore/gi, "_")
      .replace(/caret/gi, "^")
      .replace(/vertical bar/gi, "|");

    setTextToCopy(formattedTranscript); // Update the text to copy with punctuation
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <p>Your browser does not support speech recognition.</p>;
  }

  return (
    <>
      <div className="container">
        <h2>Speech to Text Converter</h2>
        <br />
        <p>
          A React hook that converts speech from the microphone to text and makes it
          available to your React components.
        </p>

        <div className="main-content">{textToCopy || "Start speaking to see the transcript..."}</div>

        <div className="btn-style">
          <button onClick={setCopied}>
            {isCopied ? "Copied!" : "Copy to clipboard"}
          </button>
          <button onClick={saveTranscript}>Download Transcript</button>
          {isListening ? (
            <button onClick={stopListening}>Stop Listening</button>
          ) : (
            <button onClick={startListening}>Start Listening</button>
          )}
          {isListening && !isPaused && (
            <button onClick={pauseListening}>Pause Listening</button>
          )}
          {isPaused && (
            <button onClick={resumeListening}>Resume Listening</button>
          )}
        </div>

        {/* Visual feedback when listening */}
        {isListening && <p>🎙️ Listening...</p>}
      </div>
    </>
  );
};

export default App;
